<template>
  <div class="PageBox">
    <div class="logo">
      {{$t('home.label[0]')}}
    </div>
      <div class="ScrollBox loginpanel">
        <div v-show="reg_type==0" class="regType">
          <h2>{{$t('register2[0]')}}</h2>
          <div style="margin:0 auto;text-align: right;width:50%">
          <van-button color="#13171A" size="mini" @click="changeType(1)" style="font-size: 20px"
                      native-type="button">
            {{$t('register2[1]')}}</van-button>
          </div>
        </div>
        <div v-show="reg_type==1" class="regType">
          <van-button color="#13171A" size="mini" @click="changeType(0)" style="font-size: 20px"
                      native-type="button">
            {{$t('register2[0]')}}</van-button>
          <div style="margin:0 auto;text-align: right;width:50%">
          <h2>{{$t('register2[1]')}}</h2>
          </div>
        </div>
        <van-field
          type="text"
          autocomplete="off"
          style="height: 0; width: 0; padding: 0; position: absolute"
        />
        <van-field
          type="password"
          autocomplete="off"
          style="height: 0; width: 0; padding: 0; position: absolute"
        />
        <van-field left-icon="./static/icon/user.png" clearable :border="false" v-if="reg_type==0">
          <template #input>
             <input
              v-model.trim="postData.username"
              :placeholder="$t('register.placeholder[0]')"
              style="border: 0; flex: 1; width: 100px; background: transparent"
            />
          </template>
        </van-field>
        <van-field left-icon="tel" clearable :border="false" v-if="reg_type==1">
          <template #input>
            <van-dropdown-menu>
              <van-dropdown-item :title="`+${postData.dest}`" ref="DropdownItem">
                <template>
                  <ul>
                    <li v-for="item in areaList" style="color: #aaa"
                        :key="item.id" :class="{on:postData.dest==item.id}"
                        @click="postData.dest=item.id,$refs.DropdownItem.toggle()">
                      <span>+{{item.id}}&#12288;{{item.name}}</span></li>
                  </ul>
                </template>
              </van-dropdown-item>
            </van-dropdown-menu>
            <input type="tel" v-model.trim="postData.username" :placeholder="$t('register2[2]')" style="border: 0; flex: 1; width: 100px; background: transparent"
            />
          </template>
        </van-field>
        <van-field
          left-icon="./static/icon/smscode.png"
          v-model.trim="postData.smscode"
          autocomplete="off"
          type="digit"
          :placeholder="$t('register.placeholder[1]')"
          clearable
          :border="false"
        >
          <template #button>
            <van-button style="margin-right:-30px"
              size="mini"
              color="#AD890C"
              round
              native-type="button"
              @click="getSmsCode"
              :loading="isSendCode"
              :loading-text="sendText"
              >{{ $t("register.text[2]") }}</van-button
            >
          </template>
        </van-field>
        <van-field
          left-icon="./static/icon/pwd.png"
          type="password"
          v-model.trim="postData.password"
          :placeholder="$t('register.placeholder[2]')"
          clearable
          autocomplete="off"
          :border="false"
          :type="showPass1 ? 'text' : 'password'"
          :right-icon="showPass1 ? 'eye' : 'closed-eye'"
          @click-right-icon="showPass1 = !showPass1"
        />
        <van-field
          left-icon="./static/icon/pwd.png"
          v-model.trim="postData.re_password"
          :placeholder="$t('register.placeholder[3]')"
          clearable
          autocomplete="off"
          :border="false"
          :type="showPass ? 'text' : 'password'"
          :right-icon="showPass ? 'eye' : 'closed-eye'"
          @click-right-icon="showPass = !showPass"
        />
        <van-field
          left-icon="./static/icon/invite.png"
          :readonly="recommendDis"
          v-model.trim="postData.recommend"
          :placeholder="$t('register.placeholder[4]')"
          clearable
          autocomplete="off"
          :border="false"
        />
        <Loading />
        <div style="padding: 33px 16px;text-align: center">
          <p class="btn" @click="onSubmit()">{{ $t('register.text[4]') }}</p>
            <router-link class="href" slot="a" to="/login">{{
              $t("register.label[1]")
            }}</router-link>
        </div>
    </div>
    <div class="BrowserTips" v-show="showBrowserTips">
      <img :src="'./static/images/down-zy.png'" width="80%" />
    </div>
    <div
      style="text-align: center; width: 380px; height: 260px"
      id="captcha"
    ></div>
  </div>
</template>
<script>
  import Service from "@/common/Service";
import $ from "jquery";

import fpjsfpjs from "@fingerprintjs/fingerprintjs";
import config from "@/config";
import $Dialog from "../common/Dialog";

let Language = config.Language;
const AppDown = config.AppDown;
export default {
  name: "Register",
  components: {},
  props: ["recommendId", "appRegister"],
  data() {
    return {
      postData: {
        dest: 84,
        username: "",
        password: "",
        re_password: "",
        smscode: "",
        code: "",
        verify_data: "",
        code_rand: "",
        fp: "dft",
        submit_key: "",
        rkey: null,
        recommend: this.recommendId || "",
        uniqueID: localStorage["phoneId"] || "", //手机唯一标识
      },
      sendText:'',
      reg_type:0,
      recommendDis: this.recommendId ? true : false,
      countdown:120,
      timer:null,
      areaList: [
        {
          id: "84",
          name: "Thailand(ประเทศไทย)",
        },
      ],
      submitRegister: "",
      showSlideCapche: false,
      isSendCode: false,
      isSubmit: false,
      codeImg: "",
      testtt: "aaa",
      showBrowserTips: false,
      downUrl: "",
      isPlus: false,
      showPass1: false,
      showPass: false,
      searchValue: "",
      dests:{"vn":"84","en":"84"},
      copyAreaList: [],
    };
  },
  computed: {},
  created() {
    console.log("pro1 " + this.recommendId);
    console.log("pro2 " + this.appRegister);
    console.log(this.postData.dest);
  },
  mounted() {
    this.$Util.Loading(false);
    if (window.plus) {
      this.isPlus = true;
    }
    this.sendText = this.$t('register.text[1]');
    this.$Model.SmsCode((data) => {
      this.areaList = data
      this.postData.dest = "84"
    })
    console.log(this.postData.recommend);
  },
  activated() {},
  destroyed() {},
  beforeDestroy() {
    console.log("clear timer");
    clearInterval(this.timer);
  },
  watch: {},
  methods: {
    changeType(reg_type){
      this.reg_type=reg_type;
      this.timer = null;
      clearInterval(this.timer);
      console.log("change type");
      const lang = localStorage["Language"] || "en";
      if (this.reg_type==1&&this.dests[lang]){
        this.postData.dest = this.dests[lang];
      }else{
        this.postData.username = null
      }
    },
    getCode() {
      this.postData.code_rand = new Date().getTime();
      this.codeImg =
        this.ApiUrl + "/api/Account/code?code_rand=" + this.postData.code_rand;
    },
    onSubmit() {
      if (!this.postData.username) {
        this.$Dialog.Toast(this.$t("register.placeholder[0]"));
        return;
      }
      if(!this.postData.recommend){
        this.$Dialog.Toast(this.$t('register.placeholder[4]'))
        return
      }
      if (!this.postData.smscode) {
        this.$Dialog.Toast(this.$t("register.placeholder[1]"));
        return;
      }
      if (!this.postData.password) {
        this.$Dialog.Toast(this.$t("register.placeholder[2]"));
        return;
      }
      if (!this.postData.re_password) {
        this.$Dialog.Toast(this.$t("register.placeholder[3]"));
        return;
      }
      if (this.postData.password != this.postData.re_password) {
        this.$Dialog.Toast(this.$t("register.placeholder[5]"));
        return;
      }

      this.isSubmit = true;
      this.$Model.UserRegister(this.postData, (data) => {
        this.isSubmit = false;
        if (data.code == 1) {
          this.$Dialog.Toast(this.$t('common3[0]'));
          this.$router.replace("/login?username="+this.postData.username);
        }else{
          let msg = this.$t(data.code_dec);
          this.$toast(msg);
        }
      });
    },
    settime() {
      var that = this;
      console.log("timer"+that.countdown);
      if (that.countdown == 0) {
        that.sendText = that.$t('register.text[1]');
        that.countdown = 120;
        that.isSendCode = false;
        clearTimeout(that.timer)
        return;
      } else {
        that.sendText = "("+that.countdown+")";
        console.log("set text:"+that.sendText);
        that.countdown--;
      }
      that.timer = setTimeout(function() {
        that.settime()
      },1000)
    },
    getSmsCode() {
      var reg = /^[0-9]+$/;
      let email = this.postData.username;
      let a = reg.test(email);
      if (!email) {
        this.$Dialog.Toast(this.$t("register.placeholder[0]"));
        return;
      }else if ((this.reg_type==0)&&(email.split("@").length==1 || email.split(".").length==1)) {
        this.$Dialog.Toast(this.$t("register.placeholder[7]"));
        return;
      }else if (this.reg_type==1&&!reg.test(email)) {
        this.$Dialog.Toast(this.$t("register2[2]"));
        return;
      }
      this.settime();
      this.isSendCode = true;
      this.$Model.GetRegCode(
        {
          reg_type:this.reg_type,
          mail: this.postData.username,
          code: this.postData.code,
          dest: this.postData.dest,
        },
        (data) => {
          if (data.code == 0) {
            $Dialog.Toast(data.code_dec);
          }
        }
      );
    },
    doShow2(){
      _MEIQIA('showPanel');
    },
  },
};
</script>

<style scoped>
  .PageBox {
    color: #635327;
    padding: 0;
    background-color: #13171A;
  }

  .regType {
    margin: 20px;
    margin-top: 0px;
    width: 100%;
    margin-bottom: 0px;
    color:#F0B80D;
    display: flex;
    padding: 10px;
  }


  .van-dropdown-menu >>> .van-dropdown-menu__bar {
    height: auto;
    box-shadow: none;
    background: transparent;
  }

  .van-dropdown-menu >>> .van-dropdown-menu__title {
    padding: 0 10px 0 0;
    margin-right: 10px;
    color: #D7D7D7;
  }

  .van-field >>> .van-dropdown-menu >>> .van-dropdown-item__content ul li {
    padding: 0 15px;
    height: 254px !important;
    color: #eee;
  }

  .van-dropdown-menu >>> .van-dropdown-item__content ul li {
    padding: 4px 0;
    width: 100%;
    font-size: 14px;
    background-color: #191C23;
    color: #eee;
  }

  .van-dropdown-menu >>> .van-dropdown-item__content ul li.on {
    color: #eee;
  }
  .van-dropdown-menu >>> .van-dropdown-item__content ul li span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .van-dropdown-menu >>> .van-dropdown-item__content ul li + li {
    color: #333;
  }

.BrowserTips {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: right;
  z-index: 99999;
}

.van-nav-bar >>> .van-nav-bar__text {
  color: #999;
}

.slidercaptcha .card-body {
  padding: 1rem;
}

.slidercaptcha canvas:first-child {
  border-radius: 4px;
  border: 1px solid #e6e8eb;
}

.slidercaptcha.card .card-header {
  background-image: none;
  background-color: rgba(0, 0, 0, 0.03);
}

.refreshIcon {
  top: -54px;
}

.href {
  color: #B5B5B5;
}

.header_bg {
  width: 100%;
  z-index: 0;
}

.register_title {
  font-size: 27px;
  color: #F46926;
  z-index: 10;
  padding-top: 210px;
  padding-left: 50px;
  font-family: Alibaba;
}

  .logo {
    width: 100%;
    font-size: 25px;
    color: #fff;
    text-align: left;
    margin-top: 30px;
    margin-left: 30px;
  }

.loginpanel {
  height:580px;
  background-size: cover;
  width: 100%;
  padding-bottom: 30px;
}

.logo img {
  margin: 20px auto;
  text-align: center;
  width: 200px;
}
.van-form {
  padding: 0 35px;
}

.van-cell--borderless {
  width: 85%;
  background-color: #191C23;
  border-radius: 5px;
  overflow: hidden;
  color:#fff;
  margin-top: 25px;
  margin-left: 30px;
  padding: 0 30px 0 13px;
  height: 45px;
}

.van-cell--borderless >>> .van-icon {
  font-size: 25px !important;
  color: #fff !important;
  margin-top: 8px;
}

.van-cell--borderless >>> .van-field__right-icon .van-icon-closed-eye {
  font-size: 21px !important;
  color: #fff !important;
  margin-right:-10px;
}

.van-button--danger {
  max-width: 200px;
  margin: auto;
  font-family: Alibaba;
  font-size: 15px !important;
  font-weight: bold;
  background: linear-gradient(90deg, #fff, #fff);
  color: #ccc;
  border-radius: 1.2rem;
  height: 46px;
}

.van-cell--borderless >>> .van-field__right-icon .van-icon-eye {
  font-size: 18px !important;
  color: #fff !important;
  margin-right:-10px;
}

.van-cell--borderless >>> .van-field__control {
  color: #fff !important;
  padding-left: 4px;
}

.van-nav-bar >>> .van-nav-bar__text {
  color: #333;
  font-size: 15px;
  font-family: Alibaba;
  position: relative;
}

.Site .van-nav-bar >>> .van-icon {
  color: #333;
}

.van-search__content {
  padding: 0;
}

.van-search__content >>> .van-cell {
  margin: 0;
  padding-left: 15px;
}

.PageBox >>> .van-dropdown-item {
}

.PageBox >>> .van-overlay {
  background-color: transparent;
}

.goLo a {
  color: #fe889d;
}

.PageBox >>> .van-popup ul > li {
  font-size: 17px !important;
  display: flex;
  justify-content: space-between;
}

.PageBox >>> .van-popup .van-search__action div {
  width: 22px;
  display: flex;
  align-items: center;
}

.van-search__content >>> .van-cell {
  height: 35px;
  border-radius: 1rem;
}

.van-search__content >>> .van-icon {
  display: none;
}

.van-search {
  padding: 20px 0 6px;
}

.PageBox >>> .van-search__action div img {
  width: 100%;
}

.van-cell >>> .van-field__control::-webkit-input-placeholder,
.van-cell >>> .van-field__control > input::-webkit-input-placeholder {
  color: #939393 !important;
  font-size: 19px;
  font-family: Alibaba;
}

.van-cell >>> .van-field__control::-moz-placeholder,
.van-cell >>> .van-field__control > input::-moz-placeholder {
  color: #939393 !important;
  font-size: 19px;
  font-family: Alibaba;
}

.van-cell >>> .van-field__control:-ms-input-placeholder,
.van-cell >>> .van-field__control > input::-ms-input-placeholder {
  color: #939393 !important;
  font-size: 18px;
  font-family: Alibaba;
}

/* .van-cell>>>.van-dropdown-menu::-webkit-input-placeholder{
		color: #777;
		font-size: 19px;
		font-family: Alibaba;
	} */
.van-cell >>> .van-cell__value,
.van-cell >>> .van-field__body,
.van-cell >>> .van-field__control,
.van-cell >>> .van-dropdown-menu,
.van-cell >>> input {
  height: 100%;
}

.van-cell >>> input {
  color: #fff !important;
  padding-left: 7px !important;
  height: 100%;
  font-size: 18px;
}

.van-cell >>> .van-ellipsis {
  font-size: 18px;
  line-height: 45px;
  color: #fff;
}

.van-cell >>> .van-dropdown-menu__title::after {
  background-size: 100% 100%;
  width: 9px;
  height: 5px;
  border: none;
  transform: rotate(0deg);
  top: 62%;
  right: -7px;
}

.goLo {
  text-align: left;
  color: rgb(78, 81, 191);
  margin-top: 20px;
  font-size: 15px;
  font-family: Alibaba;
}

.van-nav-bar >>> .van-nav-bar__text::after {
  content: "";
  position: absolute;
  background-size: 100% 100%;
  width: 9px;
  height: 5px;
  top: 9px;
  right: -15px;
}

.Site >>> .van-nav-bar__right {
  padding-right: 40px;
}
.van-cell >>> .van-field__button .van-button--info {
  background-color: #ccc;
  border: none;
}


.PageBox a:link {
  color: #635327;
}

.btn {
  width: 85%;
  padding: 10px 50px;
  border-radius: 5px;
  background-color: #F0B80D;
  color: #000;
  font-size: 18px;
  text-align: center;
  margin: 15px 30px 30px;
}
</style>
